<script>

import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
import kefu from "@/views/kefu.vue";
import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {
  components:{
    bottomTip:bottomTip,
    kefuMobile,
    Anxunheader:Anxunheader,
    kefu:kefu,
    headtip:headtip,

  },
  data(){
    return{
      model:false,
      texttotal:{
        headertitle1:"软件定制化设计及开发服务\n\n",
        headertitle2:"量身定制符合客户业务需求的软件应用，确保高效交付与卓越性能\n\n",
        headerimg:require("@/assets/Inspection1.png"),
        introduce:"基于安全法规和行业标准，构建隐患与风险知识库，结合多终端技术，实现风险管控、隐患排查、整改、验收及管理。通过智能分析、预测预警和安全绩效管理，提升安全管理效率和风险控制能力。\n"
      },
      data:[
        {
          title:"业务痛点与挑战",
          img:"/exploit1.png"
        },
        {
          title:"服务流程",
          title2:"需求分析与架构设计",
          img:"/exploit2.png"
        },
        {
          title2:"软件开发和测试",
          img:"/exploit3.png"
        },
        {
          title2:"持续迭代和优化",
          img:"/exploit4.png"
        },
        {
          title2:"系统集成与对接",
          img:"/exploit5.png"
        },
        {
          title:"我们的优势",
          img:"/exploit6.png"
        },
        {
          title:"客户收益",
          img:"/exploit7.png"
        },
      ],
      data2:[
        {
          title:"业务痛点与挑战",
          img:"/exploit11.png"
        },
        {
          title:"服务流程",
          title2:"需求分析与架构设计",
          img:"/exploit12.png"
        },
        {
          title2:"软件开发和测试",
          img:"/exploit13.png"
        },
        {
          title2:"持续迭代和优化",
          img:"/exploit14.png"
        },
        {
          title2:"系统集成与对接",
          img:"/exploit15.png"
        },
        {
          title:"我们的优势",
          img:"/exploit16.png"
        },
        {
          title:"客户收益",
          img:"/exploit17.png"
        },
      ],

    }
  },
  methods:{
    setback(index){
      return index%2;
    },
    ResizeContainer(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      console.log("123123232",screenWidth)
      if (screenWidth <= 700) {
        this.model = true
      } else {
        this.model = false
      }
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.texttotal['headertitle2']);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.texttotal['headertitle1']);
      }
    },
  },
  mounted() {
    document.title = this.texttotal['headertitle1'];
    this.setMetaTags()
    console.log("mountedmoujtned")
    this.ResizeContainer()
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
        pageTitle: this.texttotal['headertitle1'],
        pageDescription: this.texttotal['headertitle2'],
        pageKeywords: '智慧场站，生产工艺管理，智慧安防管理，AR 智能作业，AR 智能巡检，设备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',
      };
      shareUrl(shareData);
    }, 1000);
  },
}
</script>

<template>
  <div v-if="model==false" ref="total" class="totalmakeHole" >
    <div :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}" style="z-index: 10;padding-left: 3vw;width: 100%" class="topback">
      <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
    </div>
    <kefu @toptop="topTop"></kefu>
    <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="$refs.consultTip.dialogVisible=true"></Anxunheader>

    <div v-for="item,index in data"  :key="item" class="screenmake1" :style="index % 2 == 1?{'background-color':'white'}:{'background-color':'#ebf1fd'}">
      <div v-if="item.title" v-sliden-in="{duration:800}" class="heighLight" v-html="item.title"></div>
      <div v-if="item.title2" v-sliden-in="{duration:800}" class="heighLight1" v-html="item.title2"></div>
      <img :src="require('@/assets'+item.img)">
    </div>
  </div>
  <div v-else ref="totalModel" class="totalmakeHoleModel">
    <div class="screen1" style="position: relative">
      <kefuMobile @topMobile="topTopmobile"></kefuMobile>
      <headtip @showDialog="showPartner"></headtip>
      <!--      logo  you气通GPT社区 合作伙伴  登录-->

      <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
      <div class="layer2">
        <div v-sliden-instep="{duration:800}"  class="title1" style="margin: 10px 0px;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
        <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
          {{texttotal.headertitle1}}
        </div>
        <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
          {{texttotal.headertitle2}}
        </div>
        <!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->
      </div>
      <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
        <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
        <div style="font-weight: bolder" class="titlle3" @click="$refs.consultTip.dialogVisible=true">
          产品咨询
        </div>
      </div>
      <!--      平台大图-->
      <div class="layer3"></div>
    </div>
    <div v-for="item,index in data2"  :key="item" class="screenmake1" :style="index % 2 == 1?{'background-color':'white'}:{'background-color':'#ebf1fd'}">
      <div v-if="item.title" v-sliden-in="{duration:800}" class="heighLight" v-html="item.title"></div>
      <div v-if="item.title2" v-sliden-in="{duration:800}" class="heighLight1" v-html="item.title2"></div>
      <img :src="require('@/assets'+item.img)">
    </div>
    <div ref="bottom">
      <bottomTip></bottomTip>
    </div>
  </div>

</template>

<style scoped lang="scss">
.totalmakeHole{
  .screenmake1 {
    padding: 5.78739184177998vh 8.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;
    display: flex;
    flex-direction: column;
    .heighLight {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-weight: bold;
      font-size: 2.2060658579vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;
    }
    .heighLight1 {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-weight: bold;
      font-size: 2vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;
    }
    img{
      width: 70vw;
      margin: auto;
    }
  }
}
.totalmakeHoleModel{
  .screen1 {
    position: relative;
    width: 100%;
    height: 600px;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../../assets/bannerModel.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      position: absolute;
      top: 85px;
      left: 9.17157712305026vw;

      .title1 {
        width: 200px;;
        font-size: 3.50606585788562vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
      }

      .title2 {
        margin-top: 5px;
        width: 95%;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #4B5A8B;
        line-height: 24px;
      }

      .title4 {
        margin-top: 15px;

        height: 22px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 0px;
        font-style: normal;
        text-transform: none;
      }


    }

    .titlle3 {
      //position: absolute;
      //bottom: 30px;
      //left: 50%;
      //transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: auto;
      padding: 5px 4.6875vw;
      height: 35px;
      color: white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }

  }
  .screenmake1 {
    padding: 5.78739184177998vh 2.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;
    display: flex;
    flex-direction: column;
    .heighLight {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-weight: bold;
      font-size: 6vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;
    }
    .heighLight1 {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-weight: bold;
      font-size: 5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;
    }
    img{
      width: 90vw;
      margin: auto;
    }
  }
}
</style>
