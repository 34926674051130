<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :demo="'http://52.82.100.105:90/dashboard'" :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="clickConsult('油气通')"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh;background-color: #fdfdfd">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 2vw;font-weight: bolder;text-align: center">
                产品介绍
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 2vw;font-weight: bolder;margin-top: 2vh;text-align: justify" v-html="texttotal.introduce">
              </div>
            </div>
          </div>
          <div class="screenmake112" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">业务痛点与挑战</div>
            <div class="grid12" style="height: fit-content">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake111" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down :padw="4" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>

<!--          <div class="screenmake113" style="background-color: white">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">产品架构</div>-->
<!--            <div style="padding: 1vw 10vw ">-->
<!--              <img  src="@/assets/Inspection2.png">-->
<!--            </div>-->
<!--          </div>-->
          <div class="screenmake0" style="padding-top:5vh;background-color: transparent;">
            <div v-sliden-in="{duration:800}" class="heighLight" style="">核心功能</div>
            <div class="grid">
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartstation41.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: contain"
                           @click="$refs['showModel'].showModalTrue(require('@/assets/smartstation41.png'))">
<!--                           @click="showModalTrue(require('@/assets/smartstation41.png'))">-->
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[0].title">
                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[1].title">
                    </div>
                  </div>
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartstation42.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="$refs['showModel'].showModalTrue(require('@/assets/smartstation42.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartstation43.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="$refs['showModel'].showModalTrue(require('@/assets/smartstation43.png'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[2].title">
                    </div>
                  </div>
                </div>
              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign" style="margin-left: 5vw">
                    <div class="title2" v-html="cores[3].title">
                    </div>
                  </div>
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" :src="require('@/assets/smartstation44.png')"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: CONTAIN"
                           @click="$refs['showModel'].showModalTrue(require('@/assets/smartstation44.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" style="object-fit: contain" @click="$refs['showModel'].showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
          <consultTip
              :dialogVisible="dialogVisible"
              :thisTitle="title"
              @hideDialog="hideDialog"
          ></consultTip>
<!--          <div v-if="showModal" class="modal-overlay" @click="closeModal">-->
<!--            <div class="modal-content">-->
<!--              <img :src="largeSrc" alt="Large Image" class="large-image">-->
<!--            </div>-->
<!--          </div>-->
          <show-modal ref="showModel" ></show-modal>
        </div>
      </div>
    </div>
    <div v-else>
      <div ref="totalModel" class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1" style="position: relative">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <div class="layer2">
              <div v-sliden-instep="{duration:800}"  class="title1" style="margin: 10px 0px;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                {{texttotal.headertitle1}}
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                {{texttotal.headertitle2}}
              </div>
            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div style="font-weight: bolder" class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">产品介绍</div>
            <div class="title"  v-html="texttotal.introduce">
            </div>
          </div>

          <div class="screenmake2" style="padding-top: 15px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-bind:key="index" v-for="pain,index in yourBenfits" v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" :src="require('@/assets'+pain.imagepath)" style="border-radius: 9px;object-fit: contain">
                  </div>
                  <div class="titleDesign">
                    <div class="title1" v-html="pain.title1">
                    </div>
                    <div class="title2" v-html="pain.title2">
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="screenmake2" style="padding-top: 15px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-bind:key="index" v-for="pain,index in Pains" v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" :src="require('@/assets'+pain.imagepath)" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1" v-html="pain.title1">
                    </div>
                    <div class="title2" v-html="pain.title2">
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content;grid-template-columns: minmax(0,1fr)">
              <image-up-text-down-mobile v-for="elemnt in cores" :image-design_-width="'100%'" :imageDesign_Height="'100%'" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)"  :title2="elemnt.title"></image-up-text-down-mobile>
            </div>

          </div>

          <div class="screenmake4">
            <div  class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign" >
                        <img :src="item.img" loading="lazy"/>
                      </div>
                      <div class="titleDesign" style="width: 100%">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
import kefu from "@/views/kefu.vue";
import imageUpTextDown from "@/views/components/imageUpTextDown.vue";
import bottomTip from "@/views/bottomTip.vue";
import ImageUpTextDownMobile from "@/views/components/imageUpTextDownMobile.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import showModal from "@/views/components/showModal.vue";
import {shareUrl} from "@/utils/vxshare";
export default {
  components: {
    showModal,
    consultTip,
    PartnerTip,
    kefuMobile,
    ImageUpTextDownMobile,
    bottomTip, imageUpTextDown, kefu, Anxunheader, headtip},
  data(){
    return{
      showModal:false,
      largeSrc:'',
      title:'',
      dialogVisible:false,
      model: false,
      texttotal:{
        headertitle1:"智慧场站\n",
        headertitle2:"油气场站的设备、安防、生产流程等进行全方位数智化管理\n",
        headerimg:require("@/assets/smartstation1.png"),
        introduce:"结合现有自动化、信息化、数字孪生技术，按照“信息共享、多级监控、分散控制”的思想，建立”三维一体”的场站监控中心，实行生产调度统一管理。形成从生产现场到后台指挥为一体的应用模式。\n"
      },
      yourBenfits:[
        {
          imagepath:'/smartstation11.png',
          title1:'2个平台\n',
          title2:'<li>自控：一体化集控平台</li>\n' +
              '<li>应用：智联化管控平台</li>\n'
        },
        {
          imagepath:'/smartstation12.png',
          title1:'4大业务\n',
          title2:'<li>生产工艺管理</li>\n' +
              '<li>数字化交付</li>\n' +
              '<li>智慧协同管理</li>\n' +
              // '<li>移动APP应用</li>\n' +
              '<li>设备管理与维护</li>'
        },
        {
          imagepath:'/smartstation13.png',
          title1:'2端应用\n',
          title2:'<li>PC端</li>\n' +
              '<li>移动端</li>\n'
        }
      ],
      Pains:[
        {
          imagepath:'/smartstation21.png',
          title1:'设备接入和管理复杂\n',
          title2:'设备种类繁多、品牌不一，设备接入和管理过程复杂繁琐。'
        },
        {
          imagepath:'/smartstation22.png',
          title1:'作业流程繁琐低效\n',
          title2:'作业流程往往依赖于人工操作和经验判断，不仅效率低下，而且容易出错。'
        },
        {
          imagepath:'/smartstation23.png',
          title1:'运维成本高\n',
          title2:'场站运维需要大量的人工巡检和维护工作，人力成本高，巡检不及时或维护不到位可能导致设备故障或安全事故。'
        }
      ],
      cores:[{
        imagepath:'/smartstation41.png',
        title:"<span style=\"font-weight: bolder\">运营管理\n </span><br><li><span style='font-weight: bolder'>综合监控\n</span><br>生产区域视频全覆盖，提供实时动态运行曲线，对生产区域24小时运行情况全程监控。\n</li><li><span style='font-weight: bolder'>能耗管理\n</span><br>按照日耗电量、日耗气量、百立方米耗电等多项指标，综合分析用电动态，并且自动生成日用电分析曲线，与月度数据对比分析。\n</li><li><span style='font-weight: bolder'>报警管理\n</span><br>根据异常行为数据阈值，建立AI报警模型，综合分析违规情况(自动异常行为分析，自动预警图片推动等)。\n</li><li><span style='font-weight: bolder'>报表管理\n</span><br>设备报表、能耗报表、产量报表、耗电报等自动汇总统计。\n</li>\n"
      },{
        imagepath:'/smartstation42.png',
        title:"<span style=\"font-weight: bolder\">生产管理\n </span><br><li><span style='font-weight: bolder'>人员管理\n</span><br>人员入场登记，上下班交班等线上化管理。\n</li><li><span style='font-weight: bolder'>巡检管理\n</span><br>PC端：巡检路线及巡检点的制定及管理、周期性巡检任务的制定与下达等。<br>移动端：巡检数据的实时便捷填报。\n</li><li><span style='font-weight: bolder'>巡检日报\n</span><br>制氮机、空氮站、空气压缩机、增压机等场站关键设备巡检日报的自动生成，<br>大幅提高巡检效率。\n</li><li><span style='font-weight: bolder'>隐患管理\n</span><br>隐患问题处置流程、进度监控、问题统计等管理。</li>\n"
      },{
        imagepath:'/smartstation43.png',
        title:"<span style=\"font-weight: bolder\">设备管理\n </span><br><li><span style='font-weight: bolder'>设备信息总览\n</span><br>系统直观展示设备周期性应检修情况、保养情况、大修情况、重点设备运行情况、当年设备检修任务等。\n</li><li><span style='font-weight: bolder'>检修管理\n</span><br>灵活制定设备周期性检修计划（周、月、季、年等）；自动预警并生成检修工单；多维度展示检修任务执行情况。\n</li><li><span style='font-weight: bolder'>综合查询\n</span><br>多维度报表查询，包含设备检修计划执行统计、检修进度跟踪、大修动态监控等，助力智能化运维，全方位掌控设备状态。</li>\n"
      },{
        imagepath:'/smartstation44.png',
        title:"<span style=\"font-weight: bold\">数字孪生\n</span><br><li><span style='font-weight: bolder'>等比例三维建模\n\n</span><br>通过高精度三维扫描、BIM建模技术，构建等比例还原的数字孪生场站，管理者可远程查看场站布局和设备状态。\n</li><li><span style='font-weight: bolder'>实时数据映射与智能巡检\n\n</span><br>结合loT传感器，实时采集场站设备温度、压力等关键数据并在数字孪生模型直观呈现，实现远程巡检，减少人工巡检成本。</li>"
      }],
      success: [{
        img: require("@/assets/smartstation51.png"),
        title1: "智慧场站\n",
        title2: "<li>国内某增压脱烃站智慧场站项目\n</li><li>西非某油田智慧场站项目\n</li>",
      },{
        img: require("@/assets/smartstation52.png"),
        title1: "智慧场站\n",
        title2: "<li>国内某增压脱烃站智慧场站项目\n</li><li>西非某油田智慧场站项目\n</li>",
      }],
    }
  },
  mounted() {
    document.title = this.texttotal['headertitle1'];
    this.setMetaTags()
    this.ResizeContainer()
  },
  methods:{
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    // showModalTrue(item) {
    //   this.showModal = true;
    //   console.log(item)
    //   this.largeSrc = item
    // },
    closeModal() {
      this.showModal = false;
    },
    ResizeContainer(){
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (screenWidth <= 700) {
        this.model = true
      } else {
        this.model = false
      }
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.texttotal['headertitle2']);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.texttotal['headertitle1']);
      }
    },
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
        pageTitle: this.texttotal['headertitle1'],
        pageDescription: this.texttotal['headertitle2'],
        pageKeywords: '智慧场站，生产工艺管理，智慧安防管理，AR 智能作业，AR 智能巡检，设备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',
      };
      shareUrl(shareData);
    }, 1000);
  },
}
</script>
<style scoped>
.totalmakeHole {
  font-family: arial, sans-serif, "Microsoft Yahei";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid12 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }
    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            height: 55vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 85px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        //position: absolute;
        //bottom: 30px;
        //left: 50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .title{
        margin-top: 2vh;
        font-size: 16px;
        line-height: 23px;
        font-weight: bold;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;



        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 164px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 360px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -95px -10px;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
