<script>
import {defineComponent} from "vue";
import headtip from "@/views/headTip.vue";
import consultTip from "@/components/consultTip.vue";

export default defineComponent({
  components: {consultTip, headtip},
  data(){
    return{
      dialogVisible: false,
      title: 'GPT Assistants',
    }

  },
  methods:{
    clickConsult(item) {

      console.log(item)
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },

  }
})
</script>

<template>
  <div class="assistantsContainer">
    <div class="header" style="padding:1.5vh 5vw;background-color: #f2f1f4">
      <headtip @showDialog="showPartner"></headtip>
    </div>
    <a href="/">
      <div style="position: absolute;top: 70px;left: 10px">
        <img style="width: 40px;height: 20px" src="@/assets/back1.png">
      </div>
    </a>

    <div class="title1">GPT助手</div>
    <div class="title2" style="margin: 0 7vw">让您一键即可访问主流人工智能工具模型</div>
    <div style="width: 100%;height: 2vw;;padding: 1vh 7vw 3vh">
      <div class="title2" style="display: flex;align-items: center;margin: auto">
        <img src="@/assets/kefu3.png" style="height: 4vw;width: 4vw">
        <span style="color:#f97420;">若想定制化的使用培训与开发,</span>

      </div>
      <div style="background-color: #1b1e76;color: white;padding: 0.1vh 0.5vw;margin-top: 0.8vh;border-radius: 9px;cursor: pointer;width: fit-content" @click="clickConsult('定制化AI工具')" >联系我们</div>
    </div>
    <div class="assistants" style="display: grid;grid-template-columns: minmax(0,1fr) ;padding: 0 5vw;margin-top: 6vh">
      <a href="https://www.deepseek.com/" target="_blank">
        <div class="assistant" >
          <img  src="@/assets/deepseek.png">
          <div class="contentDesign">
            <div class="t1">深度求索</div>
            <div class="t2">
              DeepSeek 以混合专家架构等创新技术，实现低训练成本与高性能，通过开源策略赋能开发者，具备低延迟与高扩展性。
            </div>
          </div>
        </div>
      </a>
      <a href="https://www.doubao.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/dou.png">
          <div class="contentDesign">
            <div class="t1">豆包</div>
            <div class="t2">
              豆包依托字节跳动先进技术与海量数据，语言理解精准、生成灵活，能给出简洁实用建议，支持多模态交互，满足多样需求。
            </div>
          </div>
        </div>
      </a>
      <a href="https://tongyi.aliyun.com/qianwen/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/tong.png">
          <div class="contentDesign">
            <div class="t1">通义千问</div>
            <div class="t2">
              通义千问是阿里云自研大模型，基于海量数据训练，能实现多轮对话、文案创作、逻辑推理，还支持多模态理解与多语言交互。
            </div>
          </div>
        </div>
      </a>
      <a href="https://chatglm.cn/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/zhi.png">
          <div class="contentDesign">
            <div class="t1">智谱清言</div>
            <div class="t2">
              智谱清言基于 ChatGLM 系列模型，经海量文本与代码预训练及有监督微调，能处理多领域复杂任务，还融入灵感大全模块助力用户提效 。
            </div>
          </div>
        </div>
      </a>
      <a href="https://xinghuo.xfyun.cn/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/xun.png">
          <div class="contentDesign">
            <div class="t1">讯飞星火</div>
            <div class="t2">
              讯飞星火是科大讯飞研发的认知智能大模型，具备多维度能力，持续升级进化，广泛应用于教育、办公、汽车等领域 。
            </div>
          </div>
        </div>
      </a>
      <a href="https://hunyuan.tencent.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/teng.png">
          <div class="contentDesign">
            <div class="t1">腾讯混元</div>
            <div class="t2">
              腾讯混元是腾讯全链路自研的大语言模型，有超千亿参数规模，具备多维度能力，注重安全隐私保护。

            </div>
          </div>
        </div>
      </a>
      <a href="https://home.tiangong.cn/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/tian.png">
          <div class="contentDesign">
            <div class="t1">天工AI</div>
            <div class="t2">
              天工 AI 是由中国电信打造的大模型，融合海量数据与电信行业优势，行业赋能等场景表现出色，助力数字生活与产业升级。

            </div>
          </div>
        </div>
      </a>
      <a href="https://yiyan.baidu.com/" target="_blank">
        <div class="assistant">
          <img  src="@/assets/ge.png">
          <div class="contentDesign">
            <div class="t1">文心一言</div>
            <div class="t2">
              文心一言是百度基于文心大模型打造的知识增强大语言模型，知识储备丰富，能灵活进行自然语言交互，在各行业广泛赋能应用。
            </div>
          </div>
        </div>
      </a>

    </div>
    <consultTip
        :thisTitle="title"
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>
</template>

<style scoped lang="scss">
.assistantsContainer{
  width: 100%;
  .topback {
    position: fixed;
    z-index: 9;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .title1{
    font-size: 6vw;
    margin: 1vh  auto auto;
    padding: 2vh 0;
    text-align: center;
    font-weight: bolder;
  }
  .title2{
    font-size: 4vw;
    text-align: left;
  }
  .assistant{
    margin: 1vh 2vh;
    padding: 2vh;
    border-radius: 9px;
    //padding: 4vh 6vw 4vh 2vw;
    display: flex;
    align-items: center;
    background-color: white;
    //border: 1px solid gray;
    img{
      width: 12vw;
      height: 12vw;
    }
    .contentDesign{
      margin-left: 4vw;
      .t1{
        font-size: 4vw;
        font-weight: bolder;
      }
      .t2{
        margin-top: 1vh;
        font-size: 3vw;
      }
    }
  }
}

</style>
