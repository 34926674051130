<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <Anxunheader :title1=texttotal.headertitle1 :title2=texttotal.headertitle2 @clickConsult1="$refs.consultTip.dialogVisible=true"></Anxunheader>

          <div style="display: flex;width: 100%;padding: 5.7873918418vh 8.0976314269vw;height: 60vh">
            <div style="width: 50%;height: 100%">
              <img style="width: 100%;height: 100%" :src=texttotal.headerimg>
            </div>
            <div style="width: 50%;height: 100%;padding: 2vh 5vw">
              <div class="title1" style="font-size: 2vw;font-weight: bolder;text-align: center">
                产品介绍
              </div>
              <div class="title2" style="font-size: 1.2vw;line-height: 2vw;font-weight: bolder;margin-top: 2vh;text-align: justify" v-html="texttotal.introduce">
              </div>
            </div>
          </div>

          <div class="screenmake112" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">业务痛点与挑战</div>
            <div class="grid1" style="height: 37vh">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in Pains" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>

          <div class="screenmake111" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div class="grid1" style="height: fit-content">
              <image-up-text-down :isicon="2" v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake113" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">产品架构</div>
            <div style="padding: 1vw 4vw">
              <img  src="@/assets/Visualization2.png">
            </div>
          </div>
          <div class="screenmake114" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>
            <div class="grid1" >
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in cores" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="$refs['showModel'].showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <bottomTip></bottomTip>
          <show-modal ref="showModel" ></show-modal>
          <consultTip
              ref="consultTip"
              @hideDialog="hideDialog"
          ></consultTip>
        </div>
      </div>
    </div>
    <div v-else>
      <div ref="totalModel" class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1" style="position: relative">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}"  class="title1" style="margin: 10px 0px;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                {{texttotal.headertitle1}}
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                {{texttotal.headertitle2}}
              </div>
              <!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->
            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div style="font-weight: bolder" class="titlle3" @click="$refs.consultTip.dialogVisible=true">
                产品咨询
              </div>
            </div>

            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--          <div class="screenmake0">-->
          <!--            <div class="heighLight">智慧场站解决方案</div>-->
          <!--            <div class="heightLight2" style="font-weight: bold">智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖<span-->
          <!--                style=" color: #FF6400;">对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能</span>。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。-->
          <!--            </div>-->

          <!--          </div>-->
          <div class="screenmake0">
            <div class="heighLight">产品介绍</div>
            <div class="title"  v-html="texttotal.introduce">
            </div>
          </div>
          <div class="screenmake2" style="padding-top: 15px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-bind:key="index" v-for="pain,index in Pains" v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" :src="require('@/assets'+pain.imagepath)" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1" v-html="pain.title1">
                    </div>
                    <div class="title2" v-html="pain.title2">
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content">
              <image-up-text-down-mobile v-for="elemnt in yourBenfits" :imageDesign_Height="'100%'" :imageDesign_Width="'100%'" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">产品架构</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/Visualization2.png"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px;object-fit: contain"
                           @click="showModalTrue(require('@/assets/Visualization2.png'))">
                    </el-tooltip>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: fit-content;grid-template-columns: minmax(0,1fr) minmax(0,1fr)">
              <image-up-text-down-mobile v-for="elemnt in cores" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>

          <div class="screenmake4">
            <div  class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img" loading="lazy"/>
                      </div>
                      <div class="titleDesign" style="width: 100%">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            ref="consultTip"
            @hideDialog="hideDialog"
        ></consultTip>

      </div>
    </div>
  </div>
</template>
<script>
  import headtip from "@/views/headTip.vue";
  import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
  import kefu from "@/views/kefu.vue";
  import imageUpTextDown from "@/views/components/imageUpTextDown.vue";
  import bottomTip from "@/views/bottomTip.vue";
  import ImageUpTextDownMobile from "@/views/components/imageUpTextDownMobile.vue";
  // import videoPlayMobile from "@/components/videoPlayMobile.vue";
  import kefuMobile from "@/views/kefuMobile.vue";
  import PartnerTip from "@/components/partnerTip.vue";
  import consultTip from "@/components/consultTip.vue";
  import showModal from "@/views/components/showModal.vue";
  import {shareUrl} from "@/utils/vxshare";
  export default {
    components: {
      showModal,
      consultTip,
      PartnerTip,
      kefuMobile,
      // videoPlayMobile,
      ImageUpTextDownMobile,
      bottomTip, imageUpTextDown, kefu, Anxunheader, headtip},
    data(){
      return{
        model: false,
        texttotal:{
          headertitle1:"油田数据远程采集及传输产品\n",
          headertitle2:"实时采集、传输、存储油田设备数据，保障安全、高效管理与智能决策。",
          headerimg:require("@/assets/Visualization1.png"),
          introduce:"油田数据远程采集及传输产品提供高效、安全的数据采集、传输和存储功能，兼容WITS、Modbus、OPC等协议设备，并支持Restful API数据源，采用先进加解密技术保障传输安全，同时支持HBase、Hive等高性能数据库，实现高效存储与管理。具备详细的日志记录功能，实现全链路数据追踪，确保数据精准可溯。\n",
        },
        yourBenfits:[
          {
            imagepath:'/Visualization4.png',
            title1:'实时数据采集与传输',
            title2:'高效实时数据采集与传输，确保油田设备运行状态随时可监控，支持边缘设备的数据加密传输，实时掌握关键数据。'
          },
          {
            imagepath:'/Visualization5.png',
            title1:'数据安全与可靠管理',
            title2:'先进加密技术确保数据传输安全，防止泄漏与篡改，同时内置加解密机制保障油田数据的完整性与机密性。\n'
          },
          {
            imagepath:'/Visualization6.png',
            title1:'多协议与多数据库兼容',
            title2:'灵活兼容多种通信协议、数据接口及数据库，快速适配各类油田设备，满足多样化数据需求。'
          }
        ],
        Pains:[
          {
            imagepath:'/Visualization11.png',
            title1:'数据采集困难\n',
            title2:'油田设备种类繁多，数据传输协议各不相同，传统数据采集方式难以兼容，导致数据实时性不足，难以满足多样化的应用需求。\n'
          },
          {
            imagepath:'/Visualization14.png',
            title1:'数据传输稳定性差\n',
            title2:'油田设备通常分布在偏远且环境复杂的区域，数据传输易受网络、天气、地形等因素影响，导致稳定性下降，影响数据的连续性和可靠性。\n'
          },
          {
            imagepath:'/Visualization12.png',
            title1:'数据传输安全性不足\n',
            title2:'设备数据常通过公共或不稳定的网络传输，传统方式缺乏有效的加密防护，容易面临黑客攻击、数据泄露或篡改的风险，威胁数据安全与系统稳定性。\n'
          },
          {
            imagepath:'/Visualization13.png',
            title1:'数据孤岛问题严重\n',
            title2:'油田设备来自多个厂商，采用不同的技术标准和数据格式，导致业务数据分散在多个独立系统中，缺乏有效的数据共享与集成，无法实现实时互通与无缝整合。\n'
          }
        ],
        cores:[
          {
            imagepath:'/Visualization22.png',
            title1:'高效数据采集与传输\n',
            title2:'实时采集边缘设备数据，通过消息中间件确保数据及时准确地发送到中央数据库，提升数据流的稳定性和可靠性。\n'
          },
          {
            imagepath:'/Visualization23.png',
            title1:'多数据库支持与存储\n',
            title2:'支持多种主流数据库，MySQL、SQL Server、Oracle等，同时提供高效的分布式存储解决方案，具备强大的数据扩展能力与灵活性，满足不同规模业务场景的需求。\n'
          },
          {
            imagepath:'/Visualization25.png',
            title1:'详细的日志记录与追踪\n',
            title2:'完善的日志功能，实时追踪数据操作、传输与接收全流程，确保数据管理的全程可追溯与透明化。\n'
          },
          {
            imagepath:'/Visualization26.png',
            title1:'多种设备及协议兼容\n',
            title2:'支持WITS、Modbus、OPC多种协议设备，数据接口，灵活适配多种油田设备。\n'
          },

          {
            imagepath:'/Visualization24.png',
            title1:'加解密保障数据安全\n',
            title2:'多种加解密方式，口令验证，确保数据的安全性，防止数据泄漏和篡改，保障油田敏感数据的机密性和完整性。\n'
          },
          {
            imagepath:'/Visualization21.png',
            title1:'实时数据可视化展示\n',
            title2:'实时数据可视化，以图表和仪表盘直观展示油田设备状态、采集数据及传输信息，帮助用户快速掌握关键指标\n'
          },


        ],
        success: [{
          img: require("@/assets/Visualization3.png"),
          title1: "数据采集",
          title2: "<li>伊拉克某油田井场数据采集项目</li><li>伊拉克某油田炼化厂数据采集项目</li>",
        },
        ],
      }
    },
    mounted() {
      document.title = this.texttotal['headertitle1'];
      this.setMetaTags()
      this.ResizeContainer()
    },
    methods:{
      hideDialog() {
        this.$refs.consultTip.dialogVisible=false;
      },
      ResizeContainer(){
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        // const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (screenWidth <= 700) {
          this.model = true
        } else {
          this.model = false
        }
      },
      handleScroll() {
        if (this.model === false) {
          this.scrollHeight = this.$refs.total.scrollTop;
        } else {
          this.scrollHeight = this.$refs.totalModel.scrollTop;
        }

        if (this.scrollHeight < 100) {
          this.scrollTrue = true
        } else {
          this.scrollTrue = false
        }
        if (this.scrollHeight < 50) {
          this.scrollback = false
        } else {
          this.scrollback = true
        }
        console.log("gaodu", this.scrollHeight < 100)
      },
      setMetaTags() {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', this.texttotal['headertitle2']);
        }

        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.setAttribute('content', this.texttotal['headertitle1']);
        }
      },
    },
    created() {
      localStorage.setItem("home", 0)
      let url = window.location.href
      // let url = 'https://oilgasinfoai.com/?language=zh'
      setTimeout(() => {
        // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
        //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
        let shareData = {
          url: url,
          // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
          pageTitle: this.texttotal['headertitle1'],
          pageDescription: this.texttotal['headertitle2'],
          pageKeywords: '智慧场站，生产工艺管理，智慧安防管理，AR 智能作业，AR 智能巡检，设备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',
        };
        shareUrl(shareData);
      }, 1000);
    },
  }
</script>
<style>
.totalmakeHole {
  font-family: arial, sans-serif, "Microsoft Yahei";
  background: #ebf1fd;
  overflow-x: hidden;
  height: auto;
  position: relative;
  .makeHole1 {
    position: relative;
    width: 100%;
    .topback {
      position: fixed;
      z-index: 999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake112 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake113{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }
    }
    .screenmake114 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

      }
    }
    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;

            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }
        .el-carousel__container {
          position: relative;
          height: 52vh !important;
        }


        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }
}
.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 85px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        //position: absolute;
        //bottom: 30px;
        //left: 50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .title{
        margin-top: 2vh;
        font-size: 16px;
        line-height: 23px;
        font-weight: bold;
        font-family: arial, sans-serif, "Microsoft Yahei";
      }
      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;



        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 164px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 360px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -95px -10px;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
