<template>
  <div>
    <div v-if="model===false">
      <div class="total"  ref="total">
        <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
          <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
          <div class="screen1">
          <kefu @toptop="topTop"></kefu>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1" v-sliden-instep="{duration:800}" style="font-weight: bolder">智慧油田软件产品服务</div>
            <div class="title2" v-sliden-instep="{duration:1200}" style="font-weight: bolder;margin-top: 5vh">提供覆盖油田数据采集、远程监控、生产管理等场景的标准化软件产品，助力油田数智化转型</div>
<!--            <div class="title4" v-sliden-instep="{duration:1600}" style="margin-top: 3vh">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。-->
<!--              <span v-sliden-instep="{duration:3000}" style="color: #eb7c31;font-weight: bolder"><br>①&nbsp;设计类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</span></div>-->
            <div class="titlle3" style="font-weight: bolder" @click="clickConsult('油气通')">
             产品咨询
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake2">
<!--          <transition name="run" appear>-->
          <div v-sliden-instep="{duration:2000}" class="heighLight">   <span>智慧油田软件产品服务</span></div>
<!--          </transition>-->
          <div class="grid">
<!--            <transition name="rundown1" appear>-->
            <a href="/smartstation" target="_blank">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/software35.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">  智慧场站</div>
                    <div class="Title2">
                      <li>智能化场站运营管理</li>
                      <li>数字孪生场站建模</li>
                      <li>智能化预警与优化</li>
                    </div>
                    <div class="backconsult">
                      查看详情
                    </div>
                  </div>
                </div>


              </div>
            </a>
            <a href="/Visualization" target="_blank">
              <div class="card">
                  <!--            <div class="card" @click="detailPC1()">-->
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/software31.png">
                    </div>
                    <div class="titleDesign">
                      <div class="Title1"> 油田数据远程采集及传输产品</div>
                      <div class="Title2">
                        <li>实时精准的油田设备数据采集与同步</li>
                        <li>安全稳定的数据传输与监控</li>
                        <li>广泛兼容的设备集成能力</li>
                      </div>
<!--                      <div class="backconsult">-->
<!--                        敬请期待...-->
<!--                      </div>-->
                      <div class="backconsult">
                        查看详情
                      </div>
<!--                      <div class="backconsultArrow">-->
<!--                        点击查看<i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
<!--                      </div>-->
                      <!--                  <div class="backconsultArrow">-->
                      <!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
                      <!--                  </div>-->
                    </div>
                  </div>
                </div>
            </a>
            <a href="/smartProduct" target="_blank">
              <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/software33.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1"> 智慧建井工程管理软件</div>
                  <div class="Title2">
                    <li>油田设备实时监控和控制</li>
                    <li>钻井智能预警及可视化</li>
                  </div>
<!--                  <div class="backconsult">-->
<!--                    敬请期待...-->
<!--                  </div>-->
                  <div class="backconsult">
                    查看详情
                  </div>
                </div>
              </div>


            </div>
            </a>
            <a href="/Inspection" target="_blank">
              <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/software15.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">隐患巡查管理</div>
                  <div class="Title2">
                    <li>生产风险分级管控的精细化</li>
                    <li>多维隐患排查治理及跟踪</li>
                    <li>巡检任务自动化</li>
                  </div>
                  <div class="backconsult">
                    查看详情
                  </div>
                </div>
              </div>
            </div>
            </a>
            <a href="/smartControl" target="_blank">
              <div class="card">
              <div class="back">
                <div class="imgDesign">
                  <img loading="lazy" src="@/assets/software34.png">
                </div>
                <div class="titleDesign">
                  <div class="Title1">生产智能管控
                  </div>
                  <div class="Title2">
                    <li>油藏开发、生产运行全流程管理</li>
                    <li>精细化设备管理与智能运维</li>
                    <li>数据驱动油田决策</li>
                  </div>
                  <div class="backconsult">
                    查看详情
                  </div>
                </div>
              </div>


            </div>
            </a>
<!--            <div class="card">-->
<!--              <div class="back">-->
<!--                <div class="imgDesign">-->
<!--                  <img loading="lazy" src="@/assets/software13.png">-->
<!--                </div>-->
<!--                <div class="titleDesign">-->
<!--                  <div class="Title1">智慧QHSE管理</div>-->
<!--                  <div class="Title2">-->
<!--                    <li>企业生产风险管控的精细化</li>-->
<!--                    <li>企业生产中设备巡查任务自动化</li>-->
<!--                  </div>-->
<!--                  <div class="backconsult">-->
<!--                    敬请期待...-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


<!--            </div>-->
          </div>

        </div>
<!--        <div class="screenmake3">-->
<!--          <div class="heighLight">成功案例</div>-->
<!--          <div class="grid">-->
<!--            <el-carousel indicator-position="outside" arrow="always" interval='5000'>-->
<!--              <el-carousel-item v-for="item in success" :key="item">-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div v-left-in="{duration:800}"  class="imgDesign">-->
<!--                      <img loading="lazy" :src="item.img"/>-->
<!--                    </div>-->
<!--                    <div v-left-in="{duration:800}"  class="titleDesign">-->
<!--                      <div class="title1">{{ item.title1 }}</div>-->
<!--                      <div class="title2">{{ item.title2 }}</div>-->
<!--                    </div>-->
<!--                  </div>-->


<!--                </div>-->


<!--              </el-carousel-item>-->
<!--            </el-carousel>-->
<!--          </div>-->

<!--        </div>-->
        <bottomTip></bottomTip></div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
         <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div @click="changeheadVisable">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">智慧油田软件开发产品及服务</div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">提供覆盖油田数据采集、远程监控、生产管理等场景的标准化软件产品，助力油田数智化转型</div>
            </div>
            <div class="titlle3" @click="clickConsult('油气通')" style="font-weight:bolder;width:140px">
              产品咨询
            </div>
            <!--          <div v-zoom-in="{duration:800}" class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。-->
            <!--            <br>①&nbsp;设计类服务，由安东石油线下技术人员提供服务<br>②&nbsp;GPT开发服务，由安东人工智能专业技术人员提供服务</div>-->
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!-- <div class="screenmake0">
            <div class="heighLight">油藏地质模型架构</div>
            <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
            <div class="grid">
              <div class="card1">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/gptModel12.png">
                  </div>
                </div>
              </div>
            </div>

          </div> -->
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight">   <span>智慧油田软件开发产品及服务</span></div>
            <div class="grid">
              <a href="/station" target="_blank">
                <div v-sliden-in="{duration:800}" class="card2" style="width: 100%">
                  <div class="back">
                    <div class="imgDesign"></div>
                    <div class="titleDesign">
                      <div class="Title1">智慧场站及数字孪生</div>
                      <div class="Title2">
                        <li>智能化场站运营管理</li>
                        <li>数字孪生场站建模</li>
                        <li>智能化预警与优化</li>
                      </div>
                      <div class="backconsult"   style="font-weight: bolder;color: #ed9629">
                        查看详情
                      </div>
                    </div>
                  </div>


                </div>
              </a>

              <div v-sliden-in="{duration:800}" class="card" style="width: 100%">
                <div class="back">
                  <!--              <div class="back" @click="detail">-->
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">油田数据远程传输及可视化产品</div>
                    <div class="Title2">
                      <li>实时稳定的远程油田设备数据同步</li>
                      <li>安全可靠的远程油田设备数据传输与监控</li>
                      <li>高度兼容的设备油田设备数据集成</li>
                    </div>
                    <div class="backconsult"   style="font-weight: bolder;color: #ed9629">
                      敬请期待...
                    </div>
                    <!--                  <div class="backconsultArrow">-->
                    <!--                    <i style="font-size:14px;margin-left: 15px;" class="el-icon-right"></i>-->
                    <!--                  </div>-->
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card1" style="width: 100%">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智慧生产指挥中心</div>
                    <div class="Title2">
                      <li>集成化油田数据管理</li>
                      <li>油田设备远程监控与控制</li>
                      <li>油田开发全过程数字化</li>
                    </div>
                    <div class="backconsult"   style="font-weight: bolder;color: #ed9629">
                      敬请期待...
                    </div>
                  </div>

                </div>
              </div>

              <div v-sliden-in="{duration:800}" class="card4" style="width: 100%">
                <div class="back">
                  <div class="imgDesign"></div>
                  <div class="titleDesign">
                    <div class="Title1">智慧QHSE管理</div>
                    <div class="Title2">
                      <li>企业生产风险管控的精细化</li>
                      <li>企业生产中设备巡查任务自动化</li>
                    </div>
                    <div class="backconsult"   style="font-weight: bolder;color: #ed9629">
                      敬请期待...
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <!--        <div class="screenmake3">-->
          <!--          <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>-->
          <!--          <div v-sliden-in="{duration:800}" class="grid">-->
          <!--            <el-carousel indicator-position="outside">-->
          <!--              <el-carousel-item v-for="item in 1" :key="item">-->
          <!--                <div class="card">-->
          <!--                  <div class="back">-->
          <!--                    <div class="imgDesign">-->
          <!--                      <img loading="lazy" src="@/assets/gpt16.png">-->
          <!--                    </div>-->
          <!--                    <div class="titleDesign">-->
          <!--                      <div style="font-size: 16px; text-align: center;">XX油气田分公司页岩气井压裂项目</div>-->
          <!--                      <div class="title1">2022年，安东为某油气田分公司提供压裂工程技术服务，在长宁区块页岩气井均EUR同步提高18%，渝西区块同比提高超10%；致密气压裂新工艺井井均测试日产量达40.98万立方米，较常规工艺提高25%。</div>-->
          <!--                      &lt;!&ndash; <div class="title2">1.精细三维地应力预测及裂缝检测</div>-->
          <!--                      <div class="title2">2.新井精细跟踪服务</div>-->
          <!--                      <div class="title2">3.老井生产动态跟踪分析及评价</div> &ndash;&gt;-->
          <!--                    </div>-->
          <!--                  </div>-->


          <!--                </div>-->


          <!--              </el-carousel-item>-->
          <!--            </el-carousel>-->
          <!--          </div>-->

          <!--        </div>-->
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from '@/utils/vSlidenIn.js'; // 确保路径正确
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vLeftIn} from '@/utils/vLeftIn.js'
import {vSlidenInstep} from "@/utils/vSlidenInstep";
export default {

  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefu,kefuMobile},
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  data() {

   return {
     changeVisable:1,
      title:'',
      pageTitle: '工程方案设计GPTs _油气行业设计类大模型人工智能应用-油气通GPT',
      pageDescription: '油气通GPT云平台提供工程方案设计GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。',
      pageKeywords: '设计GPTs，工程方案设计GPTs ，基于压裂参数的EUR预测及参数反推平台，智能工程方案辅助设计平台，油气田开发设计，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
     scrollback:false,
      success:[
        {
          img:require("@/assets/gpt36.png"),
          title1:"XX油气田分公司页岩气井压裂项目",
          title2:"2022年，安东为某油气田分公司提供压裂工程技术服务，在长宁区块页岩气井均EUR同步提高18%，渝西区块同比提高超10%；致密气压裂新工艺井井均测试日产量达40.98万立方米，较常规工艺提高25%。",
        },

      ]
    }

  },

  methods: {
    topTop() {
      console.log("指定fuzujianchuanzhi")
      // const element = this.$refs.total;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // this.$nextTick(() => {
      //   this.$refs.total.scrollTop = rect
      // });
    },
    topMobile() {
      console.log("指定fuzujianchuanzhi")
      // const element = this.$refs.total;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // this.$nextTick(() => {
      //   this.$refs.total.scrollTop = rect
      // });
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail(){
      window.open("/recognition", "_blank");
    },
    detailPC1(){
      window.open("/assisted", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {

    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {

    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '工程方案设计GPTs _油气行业设计类大模型人工智能应用-油气通GPT',
        pageDescription: '油气通GPT云平台提供工程方案设计GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。',
        pageKeywords: '设计GPTs，工程方案设计GPTs ，基于压裂参数的EUR预测及参数反推平台，智能工程方案辅助设计平台，油气田开发设计，油气通，油气通GPT，油气通GPT云平台，油气通大模型，油气通GPT大模型',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.total {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 74vh;
    padding: 5.84301606922126vh 2.86077411900635vw  2.84301606922126vh 6.86077411900635vw;
    background-image: url("../../assets/gpt11.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      margin-top: 4vh;
      margin-left:2vw;

      .title1 {
        width: fit-content;
        height: 7.77626699629172vh;
        font-size: 2.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 3vh;
        width: 35vw;
        height: fit-content;
        text-align: justify;
        font-size: 1.3vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        line-height: 5vh;
      }
      .title4 {
        margin-top: 1vh;
        width: 35vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        line-height:5vh;
      }
      .titlle3{
        position: absolute;
        bottom: 7.78739184177998vh;
        margin-top: 4.07911001236094vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;

        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;


      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }
  .screenmake0 {
    padding: 7.78739184177998vh 8.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      margin: 0 auto;
      text-align: center;
      height:8.23733003708282vh;
      opacity: 1;
      font-size: 2.2060658579vw;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 4.23733003708282vh;

    }

    .heightLight2 {
      margin:0 auto;
      text-align: center;
      height: 13.78739184177998vh;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

      width: 60.9693818602vw;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 69vh;
            width: 100%;
            background-image: url("../../assets/gpt12.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow {
            transition: opacity 0.5s ease-out;
            opacity: 0;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 1.8597920277vw;
          }

        }


      }

      .card2 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          overflow: hidden;
          background: #FFFFFF;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            text-align: center;
            height: 48vh;
            width: 100%;
            background-image: url("../../assets/frack02.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }

          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }
          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }

        .back:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .screenmake2{
    padding: 7.78739184177998vh 9.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: white;

    .run-enter-active {
      animation: fadeInDown 1s ease-in-out both;
    }
    .rundown1-enter-active {
      animation: slideInUp 1s ease-in-out both;
    }
    .rundown2-enter-active {
      animation: slideInUp 1.2s ease-in-out both;
    }
    .rundown3-enter-active {
      animation: slideInUp 1.4s ease-in-out both;
    }
    .heighLight{
      display: flex;
      //justify-content: center;
      text-align: left;
      height:8.23733003708282vh;
      font-size:2.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #111111;
      line-height: 4.23733003708282vh;
    }


    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap:15px;
      .card {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;
        overflow: hidden; /* 防止子元素的绝对定位导致的问题 */
        .back {
          border-radius: 9px;
          position: relative;
          border: 2px solid white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height: fit-content;
          min-height: 70vh;
          overflow: hidden;
          transition: transform 0.8s ease-out, height 0.8s ease-out;
          background: #015377;
        }
        .imgDesign {
          overflow: hidden;
          width: 100%;
          height: 38vh;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;
          transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
          visibility: visible;
        }
        .titleDesign {
          position: absolute;
          height: 30vh;
          bottom: 2vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          max-height: 27vh;
          transition: max-height 0.5s ease-out,min-height 0.5s ease-out;
          .Title1 {
            margin: 0vh 1.8597920277vw 0 1.8597920277vw;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            height: fit-content;
            font-size: 1.5vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 700;
            transition: transform 0.8s ease-out;
            color: white;
            line-height: 2vw;
          }
          .Title2 {
            color: white;
            opacity: 1;
            max-height: 14vh;
            overflow: hidden;
            transition: opacity 0.5s ease-out;
            margin: 0 1.8597920277vw 5vh 1.8597920277vw;
            font-size: 1.1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            line-height: 4.5vh;
            flex-grow: 1;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow:hidden;
          }
          .backconsult {
            position: absolute;
            bottom: 1vh;
            width: 100%;
            left: 0px;

            text-align: center;
            font-size: 1.1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bolder;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            right: 1.8597920277vw;
          }
          .backconsultArrow {
            transition: opacity 0.5s ease-out;
            opacity: 0;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 1.1vw;
            font-family: 'Times New Roman';
            font-weight: 400;
            color: #ef9729;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 1.8597920277vw;
          }
        }
      }


      .card:hover {
        .imgDesign {
          //opacity: 0;
          //visibility: hidden;
          transform: scale(1.05);
        }

        .titleDesign{
          transform: scale(1.01);
          //max-height: 100vh;
          //min-height:65vh;
          //top:2vh;
          .Title1 {

            //display: block;
            //height: fit-content;
          }
          .Title2 {

            //opacity: 1;
            //max-height: 100vh;
            //min-height: 45vh;
          }
          .backconsult{
            //display: none;
            transform: scale(1.05);
          }
          .backconsultArrow{
            //opacity: 1;
          }
        }
      }

    }
  }
  .screenmake3{
    padding: 7.78739184177998vh 5.09763142692085vw;
    width: 100%;
    height: fit-content;
    background: #212a3c;
    //background-color: #ebf1fd;
    .heighLight{
      color:white;
      text-align: center;
      height: 7.54017305315204vh;
      font-size: 2.3vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      //color: #2168DB;
      line-height: 2.71940667490729vh;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      .card{
        //cursor: pointer;
        margin:0 auto;
        width: 86%;
        height:65vh;
        padding:0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        .back{
          border-radius: 9px;
          display: flex;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          height:60vh;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: fit-content;
            height:100%;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 1.5248454883vh 1.5248454883vw;
            color: white;
            width: 30vw;
            height: fit-content;
            margin:auto;
            text-align: justify;
            float: right;
            .title1{
              font-size: 1.3vw;
            }
            .title2{
              margin-top: 25px;
              line-height: 4vh;
              font-size: 1.1vw;
            }
            .success{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);
              column-gap: 1vw;
              margin-top: 3vh;
              .suc1{
                width: 100%;
                height: 6vh;
                .title3{
                  text-align: center;
                  margin-top: 1vh;
                  font-size: 0.8243212016vw;
                }

              }

            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height:62vh !important;
      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 550px;
      }
      ::v-deep .el-carousel__arrow {
        outline: 10vh;
        padding: 0;
        margin: 0  ;
        cursor: pointer;

        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: rgba(249, 250, 252, 0.2);
        border: rgba(255, 255, 255, 0.5) 1px solid;
        color: #fff;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-weight:800;
        font-size: 25px;
      }
    }
  }
}
.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 600px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/gptModel11.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-66px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #111111;
      line-height: 20px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;
      top:6vh;
      .title1 {
        margin:35px auto 0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width: 90%;
        text-align: center;
        height: 17px;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }
  .screenmake2{
    padding:20px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 20px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 200px;
            opacity: 1;
            background-image: url("../../assets/software11.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 215px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 35px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/software13.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 40px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card4{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/software14.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 180px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 40px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 160px;
            opacity: 1;
            background-image: url("../../assets/software12.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 175px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: 20px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 40px 12px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;

    padding:20px 15px;
    background: #212a3c;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        //background: #4D85F7 ;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 370px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:fit-content;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 14px;
              line-height: 20px;
            }
            .title2{
              margin-top: 15px;
              line-height: 20px;
              font-size:14px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 390px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
