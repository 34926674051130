<script>
export default {
  data(){
    return{
      showModal:false,
      largeSrc:''
    }
  },
  methods:{
    closeModal() {
      this.showModal = false;
    },
    showModalTrue(item) {
      console.log("电到我啦")
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
  }
}
</script>

<template>
  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content">
      <img :src="largeSrc" alt="Large Image" class="large-image">
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
